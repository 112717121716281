import React, { useRef, useEffect } from 'react';
import { saveFile } from './fire';

const Upload = ({ children, onUpload, onUploadStart, path = 'uploads' }) => {
  const editRef = useRef();
  
  useEffect(() => {
    if (editRef.current) {
      editRef.current.focus();
    }
  }, []);

  const handleSaveFile = async (file) => {
    try {
      onUploadStart?.();
      const url = await saveFile(file, path);
      onUpload(url);
    } catch (err) {
      console.log('Upload Error:', err);
    }
  };

  const upload = (e) => {
    const files = e.target.files;
    
    if (!files || !files.length) return;
    
    console.log(files);

    for (let i = 0; i < files.length; i++) {
      handleSaveFile(files[i]);
    }
  };
      
  return (
    <span>
      <input 
        type="file" 
        id="upload" 
        ref={editRef}
        style={{ display: 'none' }} 
        onChange={upload} 
      />
      <span onClick={() => editRef.current.click()}>
        {children}
      </span>
    </span>
  );
};

export { Upload };
