import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getPath, setPath, watchPath, saveFile } from './fire';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { IoNotifications } from 'react-icons/io5';
import { FaPeopleGroup, FaCheck, FaCheckDouble, FaImage, FaSpinner } from 'react-icons/fa6';
import { LuSendHorizontal, LuScreenShare } from 'react-icons/lu';
import './Chat.css';
import { v4 as uuidv4 } from 'uuid';
import { Upload } from './Upload';
import { Slideshow } from './Slideshow';
import { ModalContent } from './ModalContent';
import html2canvas from 'html2canvas';

const Chat = ({ currentUser }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [message, setMessage] = useState('');
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [conversations, setConversations] = useState({});
  const conversationsRef = useRef({});
  const isExpandedRef = useRef(false);
  const selectedUserRef = useRef(null);
  const messagesEndRef = useRef(null);
  const audioRef = useRef(new Audio('/sounds/bottleOpen.mp3'));
  const notificationTimeoutRef = useRef(null);
  const broadcastChannelRef = useRef(null);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [slideshowImages, setSlideshowImages] = useState([]);
  const [slideshowIndex, setSlideshowIndex] = useState(0);
  const [isCapturing, setIsCapturing] = useState(false);

  // Update notification permission check - simplified
  useEffect(() => {
    if ('Notification' in window && Notification.permission === 'default') {
      Notification.requestPermission();
    }
  }, []);

  // Initialize broadcast channel
  useEffect(() => {
    if ('BroadcastChannel' in window) {
      broadcastChannelRef.current = new BroadcastChannel('chat-read-status');
      
      broadcastChannelRef.current.onmessage = (event) => {
        const { userId, timestamps } = event.data;
        
        // Update local state to mark messages as read
        setConversations(prev => {
          if (!prev[userId]) return prev;
          
          const updated = { ...prev };
          timestamps.forEach(timestamp => {
            if (updated[userId][timestamp]) {
              updated[userId][timestamp] = { ...updated[userId][timestamp], read: true };
            }
          });
          
          return updated;
        });
      };
    }

    return () => {
      broadcastChannelRef.current?.close();
    };
  }, []);

  // Update markMessagesAsRead to be more precise about when messages are marked as read
  const markMessagesAsRead = useCallback(async (chatId) => {
    // Don't mark as read if chat isn't expanded or if this isn't the selected chat
    if (!isExpanded || selectedUser?.id !== chatId && selectedUser?.uid !== chatId) {
      return;
    }

    // Don't mark as read if window/tab isn't focused
    if (!document.hasFocus()) {
      return;
    }

    if (!conversations[chatId]?.messages) return;

    const unreadMessages = Object.entries(conversations[chatId].messages)
      .filter(([_, message]) => 
        message.userId !== currentUser.uid && !message.read
      );

    if (unreadMessages.length === 0) return;

    const timestamps = [];
    const chat = conversations[chatId];
    
    // Update each message individually
    for (const [timestamp, message] of unreadMessages) {
      const updatedMessage = {
        ...message,
        read: Date.now()
      };

      if (chat.isGroupChat) {
        // For group chats, only update in current user's messages
        await setPath(
          `chat/${currentUser.uid}/${chatId}/messages/${timestamp}`,
          updatedMessage
        );
      } else {
        // For direct chats, update in both users' paths
        await Promise.all([
          setPath(`chat/${currentUser.uid}/${chatId}/messages/${timestamp}`, updatedMessage),
          setPath(`chat/${chatId}/${currentUser.uid}/messages/${timestamp}`, updatedMessage)
        ]);
      }
      
      timestamps.push(timestamp);
    }

    // Update local state
    setConversations(prev => {
      if (!prev[chatId]?.messages) return prev;
      
      const updated = { ...prev };
      if (updated[chatId].messages) {
        timestamps.forEach(timestamp => {
          if (updated[chatId].messages[timestamp]) {
            updated[chatId].messages[timestamp] = {
              ...updated[chatId].messages[timestamp],
              read: true
            };
          }
        });
      }
      
      return updated;
    });

    // Broadcast to other tabs
    broadcastChannelRef.current?.postMessage({
      chatId,
      timestamps
    });
  }, [conversations, currentUser.uid, isExpanded, selectedUser]);

  // Update useEffect to be more precise about when to mark messages as read
  useEffect(() => {
    conversationsRef.current = conversations;
    selectedUserRef.current = selectedUser;
    isExpandedRef.current = isExpanded;
    scrollToBottom();

    // Only mark messages as read when chat is expanded, selected, and window is focused
    if (isExpanded && selectedUser && document.hasFocus()) {
      const chatId = selectedUser.isGroupChat ? selectedUser.id : selectedUser.uid;
      markMessagesAsRead(chatId);
    }
  }, [conversations, selectedUser, isExpanded, markMessagesAsRead]);

  // Add focus event listener to handle marking messages as read when window regains focus
  useEffect(() => {
    const handleFocus = () => {
      if (isExpanded && selectedUser) {
        const chatId = selectedUser.isGroupChat ? selectedUser.id : selectedUser.uid;
        markMessagesAsRead(chatId);
      }
    };

    window.addEventListener('focus', handleFocus);
    return () => window.removeEventListener('focus', handleFocus);
  }, [isExpanded, selectedUser, markMessagesAsRead]);

  // Load user's conversations
  useEffect(() => {
    const loadUsers = async () => {
      const { key, ...users } = await getPath('/user');
      return Object.entries(users || {})
        .filter(([key, value]) => value.chat === true && key !== currentUser.uid)
        .map(([key, value]) => ({ uid: key, ...value }));
    };

    const watchMessages = async () => {
      const users = await loadUsers();
      setAvailableUsers(users);

      // Watch all chats under the user's path
      watchPath(`/chat/${currentUser.uid}`, ({key, ...chats}) => {
        let notifyMessages = [];
        let lastMessageTimestamp = null;
        let lastChatId = null;

        // Process all chats (both direct and group)
        Object.entries(chats || {}).forEach(([chatId, chatData]) => {
          if (!chatData.messages) return;

          Object.entries(chatData.messages).forEach(([timestamp, message]) => {
            // Update last message info
            if (lastMessageTimestamp === null || timestamp > lastMessageTimestamp) {
              lastMessageTimestamp = timestamp;
              lastChatId = chatId;
            }

            // Handle notifications
            if (!conversationsRef.current?.[chatId]?.messages?.[timestamp] && !message.read) {
              if (currentUser.uid !== message.userId && (
                selectedUserRef.current?.id !== chatId || 
                !document.hasFocus() || 
                !isExpandedRef.current
              )) {
                notifyMessages.push(message);
              }
            }
          });
        });

        // Handle notifications
        if (notifyMessages.length > 0) {
          audioRef.current.play().catch(e => console.log('Audio play failed:', e));
        }

        // Update conversations state with the new structure
        setConversations(chats);

        // Set initial selected chat if none selected
        if (!selectedUserRef.current && lastChatId) {
          const chat = chats[lastChatId];
          if (chat.isGroupChat) {
            setSelectedUser({ id: lastChatId, ...chat });
          } else {
            setSelectedUser(users.find(u => u.uid === lastChatId));
          }
        }
      });
    };

    watchMessages();
  }, [currentUser.uid]);

  // Simplified to regular function
  const showNotification = (messages) => {
    if (Notification.permission === 'granted' && !document.hasFocus()) {
      // Clear any existing notification timeout
      if (notificationTimeoutRef.current) {
        clearTimeout(notificationTimeoutRef.current);
      }
      
      // Set a new timeout to show notification after a delay
      notificationTimeoutRef.current = setTimeout(() => {
        // Group messages by user
        const messagesByUser = messages.reduce((acc, msg) => {
          if (!acc[msg.userName]) {
            acc[msg.userName] = [];
          }
          acc[msg.userName].push(msg.text);
          return acc;
        }, {});

        // Create a single notification with grouped messages
        const notificationBody = Object.entries(messagesByUser)
          .map(([userName, texts]) => `${userName}: ${texts.length > 1 ? `(${texts.length} messages)` : texts[0]}`)
          .join('\n');

        new Notification('ALLThing Chat', {
          body: notificationBody,
          icon: '/favicon.ico'
        });
        
        notificationTimeoutRef.current = null;
      }, 1000); // 1 second debounce
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim() || !selectedUser) return;

    const timestamp = new Date();
    const newMessage = {
      text: message,
      userId: currentUser.uid,
      userName: currentUser.name,
      timestamp: timestamp.toISOString()
    };

    if (selectedUser.isGroupChat) {
      // Send message to all group members
      await Promise.all(
        Object.keys(selectedUser.members).map(memberId =>
          setPath(
            `/chat/${memberId}/${selectedUser.id}/messages/${timestamp.getTime()}`,
            newMessage
          )
        )
      );
    } else {
      // Direct chat message
      await Promise.all([
        setPath(`/chat/${currentUser.uid}/${selectedUser.uid}/messages/${timestamp.getTime()}`, newMessage),
        setPath(`/chat/${selectedUser.uid}/${currentUser.uid}/messages/${timestamp.getTime()}`, newMessage)
      ]);
    }
    
    setMessage('');
  };

  const getUnreadCount = (uid) => {
    if (uid) {
      // Count unread messages for specific chat (direct or group)
      const chatMessages = conversations?.[uid]?.messages || {};
      return Object.values(chatMessages)
        .filter(message => 
          message.userId !== currentUser.uid && !message.read
        ).length;
    }

    // Count all unread messages across all conversations
    return Object.entries(conversations || {})
      .reduce((total, [_, chat]) => {
        const messages = chat.messages || {};
        return total + Object.values(messages)
          .filter(message => 
            message.userId !== currentUser.uid && !message.read
          ).length;
      }, 0);
  };

  // Add helper function to check if groups have same members
  const haveSameMembers = (group1Members, group2Members) => {
    const members1 = Object.keys(group1Members).sort();
    const members2 = Object.keys(group2Members).sort();
    return (
      members1.length === members2.length && 
      members1.every((member, index) => member === members2[index])
    );
  };

  // Modify handleCreateGroup to handle single member case
  const handleCreateGroup = async (e) => {
    e.preventDefault();
    if (selectedUserIds.length === 0) return;

    // If only one user selected, switch to direct chat
    if (selectedUserIds.length === 1) {
      const selectedUser = availableUsers.find(u => u.uid === selectedUserIds[0]);
      setSelectedUser(selectedUser);
      setIsCreatingGroup(false);
      setSelectedUserIds([]);
      return;
    }

    const proposedMembers = {
      [currentUser.uid]: currentUser.name,
      ...selectedUserIds.reduce((acc, uid) => ({ 
        ...acc, 
        [uid]: availableUsers.find(u => u.uid === uid)?.name 
      }), {})
    };

    // Check existing conversations for a group with same members
    const existingGroup = Object.entries(conversations)
      .find(([_, chat]) => 
        chat.isGroupChat && haveSameMembers(chat.members, proposedMembers)
      );

    if (existingGroup) {
      // If group exists, set it as active
      const [groupId, groupChat] = existingGroup;
      setSelectedUser({ id: groupId, ...groupChat });
      setIsCreatingGroup(false);
      setSelectedUserIds([]);
      return;
    }

    // If no existing group found, create new group
    const groupId = uuidv4();
    
    // Create group chat entry for each member with personalized names
    await Promise.all(
      Object.keys(proposedMembers).map(memberId => {
        // Create group name excluding the current member's name
        const memberNames = Object.entries(proposedMembers)
          .filter(([uid]) => uid !== memberId)
          .map(([_, name]) => name)
          .sort()
          .join(', ');

        return setPath(`/chat/${memberId}/${groupId}`, {
          isGroupChat: true,
          name: memberNames,
          members: proposedMembers,
          createdBy: currentUser.uid,
          createdAt: new Date().toISOString()
        });
      })
    );

    setIsCreatingGroup(false);
    setSelectedUserIds([]);
  };

  // Update handleImageUpload to handle loading state
  const handleImageUpload = async (url) => {
    if (!selectedUser) return;

    setIsUploading(false); // Reset loading state
    const timestamp = new Date();
    const newMessage = {
      type: 'image',
      url: url,
      userId: currentUser.uid,
      userName: currentUser.name,
      timestamp: timestamp.toISOString()
    };

    if (selectedUser.isGroupChat) {
      await Promise.all(
        Object.keys(selectedUser.members).map(memberId =>
          setPath(
            `/chat/${memberId}/${selectedUser.id}/messages/${timestamp.getTime()}`,
            newMessage
          )
        )
      );
    } else {
      await Promise.all([
        setPath(`/chat/${currentUser.uid}/${selectedUser.uid}/messages/${timestamp.getTime()}`, newMessage),
        setPath(`/chat/${selectedUser.uid}/${currentUser.uid}/messages/${timestamp.getTime()}`, newMessage)
      ]);
    }
  };

  // Update handlePaste to handle loading state
  const handlePaste = async (e) => {
    const items = e.clipboardData?.items;
    if (!items) return;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      
      if (item.type.indexOf('image') !== -1) {
        e.preventDefault();
        const file = item.getAsFile();
        if (file) {
          try {
            setIsUploading(true);
            const url = await saveFile(file, `chat-images/${currentUser.uid}`);
            handleImageUpload(url);
          } catch (err) {
            console.log('Image upload error:', err);
            setIsUploading(false);
          }
        }
      } else if (item.type === 'text/plain') {
        // Check for image URLs in pasted text
        item.getAsString(async (text) => {
          const urlMatch = text.match(/https?:\/\/.*\.(?:png|jpg|jpeg|gif|webp)/i);
          if (urlMatch) {
            e.preventDefault();
            handleImageUpload(urlMatch[0]);
          }
        });
      }
    }
  };

  // Update renderMessage to order images by timestamp
  const renderMessage = (msg) => {
    if (msg.type === 'image') {
      return (
        <div className="message-image">
          <img 
            src={msg.url} 
            alt="Shared" 
            loading="lazy" 
            onClick={() => {
              // Get all image messages from the current conversation
              const imageMessages = Object.entries(
                selectedUser?.isGroupChat 
                  ? (conversations?.[selectedUser.id]?.messages || {})
                  : (conversations?.[selectedUser.uid]?.messages || {})
              )
                .filter(([_, m]) => m.type === 'image')
                // Sort by timestamp in descending order (most recent first)
                .sort(([timestampA], [timestampB]) => timestampB - timestampA)
                .map(([_, m]) => m);

              // Get the URLs in the sorted order
              const images = imageMessages.map(m => m.url);
              
              // Find the index of the clicked image in the sorted array
              const index = images.indexOf(msg.url);
              
              setSlideshowImages(images);
              setSlideshowIndex(index);
              setSelectedImage(true);
            }}
          />
        </div>
      );
    }
    return <div className="message-text">{msg.text}</div>;
  };

  // Add function to render slideshow content
  const renderSlideshow = () => {
    return (
      <Slideshow
        images={slideshowImages}
        displayIndex={slideshowIndex}
        maxHeight={800}
        prev
        next
      />
    );
  };

  // Add screenshot capture function
  const captureScreenshot = async () => {
    try {
      setIsCapturing(true);
      
      // Get the app root element (adjust selector if needed)
      const element = document.getElementById('root');
      
      // Hide the chat window temporarily for the screenshot
      const chatElement = document.querySelector('.chat-container');
      const originalDisplay = chatElement.style.display;
      chatElement.style.display = 'none';
      
      const canvas = await html2canvas(element, {
        logging: false,
        useCORS: true,
        allowTaint: true
      });
      
      // Restore chat window
      chatElement.style.display = originalDisplay;
      
      // Convert canvas to blob
      const blob = await new Promise(resolve => 
        canvas.toBlob(resolve, 'image/png')
      );
      
      // Create a File object from the blob
      const file = new File([blob], 'screenshot.png', { type: 'image/png' });
      
      // Upload and send the screenshot
      const url = await saveFile(file, `chat-images/${currentUser.uid}`);
      await handleImageUpload(url);
      
    } catch (err) {
      console.log('Screenshot error:', err);
    } finally {
      setIsCapturing(false);
    }
  };

  return (
    <>
      <div className={`chat-container ${isExpanded ? 'expanded' : ''}`}>
        <div className="chat-header" onClick={() => setIsExpanded(!isExpanded)}>
          <div className="chat-header-content">
            <IoChatbubbleEllipsesOutline className="chat-icon" />
            <h3>Chat {selectedUser ? `- ${selectedUser.name}` : ''}</h3>
            {!isExpanded && getUnreadCount() > 0 && (
              <small className="unread-indicator">
                <IoNotifications className="notification-icon" />
                <span className="unread-count ml-1">{getUnreadCount()}</span>
              </small>
            )}
          </div>
          <button className="toggle-button">
            {isExpanded ? '▼' : '▲'}
          </button>
        </div>
        
        {isExpanded && (
          <>
            <div className="chat-users">            
              {isCreatingGroup ? (
                <form onSubmit={handleCreateGroup} className="group-creation-form">
                  <label>Select Group (Ctrl+Click):</label>
                  <select 
                    multiple
                    value={selectedUserIds}
                    onChange={(e) => setSelectedUserIds(
                      Array.from(e.target.selectedOptions, option => option.value)
                    )}
                    className="group-users-select"
                  >
                    {availableUsers.map(user => (
                      <option key={user.uid} value={user.uid}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                  <div className="group-creation-buttons">
                    <button type="submit">Create Group</button>
                    <button type="button" onClick={() => setIsCreatingGroup(false)}>
                      Cancel
                    </button>
                  </div>
                </form>
              ) : (
                <span className="chat-selector">
                  {getUnreadCount() > 0 && (
                    <span className="unread-indicator">
                      <IoNotifications className="notification-icon" />
                      <span className="unread-count ml-1">{getUnreadCount()}</span>
                    </span>
                  )}
                  <select 
                    value={selectedUser?.uid || selectedUser?.id || ''} 
                    onChange={(e) => {
                      const selectedId = e.target.value;
                      const chat = conversations[selectedId];
                      
                      if (chat?.isGroupChat) {
                        setSelectedUser({ id: selectedId, ...chat });
                      } else {
                        setSelectedUser(availableUsers.find(u => u.uid === selectedId));
                      }
                    }}
                  >
                    <option value="">Select a chat...</option>
                    <optgroup label="Direct Messages">
                      {availableUsers.map(user => (
                        <option key={user.uid} value={user.uid}>
                          {user.name} {getUnreadCount(user.uid) > 0 ? `(${getUnreadCount(user.uid)} unread)` : ''}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Groups">
                      {Object.entries(conversations)
                        .filter(([_, chat]) => chat.isGroupChat)
                        .map(([chatId, chat]) => (
                          <option key={chatId} value={chatId}>
                            {chat.name} {getUnreadCount(chatId) > 0 ? `(${getUnreadCount(chatId)} unread)` : ''}
                          </option>
                        ))}
                    </optgroup>
                  </select>
                  <button 
                    type="button" 
                    onClick={() => setIsCreatingGroup(true)}
                    className="create-group-button"
                    title="Create New Group"
                  >
                    <FaPeopleGroup />
                  </button>
                </span>
              )}
            </div>

            {selectedUser && (
              <>
                <div className="messages-container">
                  {Object.entries(
                    selectedUser?.isGroupChat 
                      ? (conversations?.[selectedUser.id]?.messages || {})
                      : (conversations?.[selectedUser.uid]?.messages || {})
                  ).map(([timestamp, msg]) => (
                    <div key={timestamp} className="message-wrapper">
                      <div className={`message ${msg.userId === currentUser.uid ? 'own-message' : ''}`}>
                        {renderMessage(msg)}
                      </div>
                      <div className={`message-meta ${msg.userId === currentUser.uid ? 'own-message' : ''}`}>
                        <span className="username">{msg.userName}</span>
                        <div className="meta-right">
                          <span className="timestamp">
                            {new Date(msg.timestamp).toLocaleTimeString()}
                          </span>
                          {msg.userId === currentUser.uid && (
                            <span className="read-status" title={msg.read ? 'Read' : 'Sent'}>
                              {msg.read ? (
                                <FaCheckDouble className="read" />
                              ) : (
                                <FaCheck />
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </div>
                
                <form onSubmit={handleSubmit} className="message-form">
                  <div className="input-container">
                    <input
                      type="text"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onPaste={handlePaste}
                      placeholder={`Message ${selectedUser.name}...`}
                    />
                    <div className="input-actions">
                      <Upload 
                        onUpload={handleImageUpload}
                        path={`chat-images/${currentUser.uid}`}
                        onUploadStart={() => setIsUploading(true)}
                      >
                        <div 
                          className="input-icon text-dimmer"
                          title={isUploading ? "Uploading..." : "Upload Image"}
                        >
                          {isUploading ? (
                            <FaSpinner className="spinner" />
                          ) : (
                            <FaImage />
                          )}
                        </div>
                      </Upload>
                      <div 
                        className="input-icon text-dimmer"
                        onClick={captureScreenshot}
                        title={isCapturing ? "Capturing..." : "Take Screenshot"}
                      >
                        {isCapturing ? (
                          <FaSpinner className="spinner" />
                        ) : (
                          <LuScreenShare />
                        )}
                      </div>
                    </div>
                  </div>
                  <button type="submit" title="Send">
                    <LuSendHorizontal />
                  </button>
                </form>
              </>
            )}
          </>
        )}
      </div>
      
      {selectedImage && (
        <ModalContent
          show={true}
          close={() => setSelectedImage(false)}
          title="Image View"
          content={renderSlideshow()}
          dialogClassName="modal-90w"
          centered
        />
      )}
    </>
  );
};

export default Chat; 