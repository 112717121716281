import React, { useState, useMemo, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import { FaFilter, FaSortUp, FaSortDown, FaTimes, FaTimesCircle, FaEye } from 'react-icons/fa';

const ResultsFilter = ({
  filterDefinitions,
  defaultSort,
  onFilterChange,
  onSortChange,
  onDisplayChange
}) => {
  const [filters, setFilters] = useState({});
  const [sorts, setSorts] = useState([]);
  const [displayCount, setDisplayCount] = useState(10);
  const [activeFilterCategory, setActiveFilterCategory] = useState(null);
  const [activeSortCategory, setActiveSortCategory] = useState(null);
  const [activeDisplay, setActiveDisplay] = useState(false);

  useEffect(() => {
    if (defaultSort && !sorts.length) {
      onSortChange([defaultSort]);
    }
  }, [defaultSort]);

  // Separate filter and sort categories based on their names
  const { filterCategories, sortCategories } = useMemo(() => {
    const filters = {};
    const sorts = {};
    Object.entries(filterDefinitions).forEach(([category, definition]) => {
      if (category.endsWith('Filter')) {
        filters[category] = definition;
      } else if (category.endsWith('Sort')) {
        sorts[category] = definition;
      }
    });
    return { filterCategories: filters, sortCategories: sorts };
  }, [filterDefinitions]);

  const toggleFilterCategory = (category) => {
    setActiveFilterCategory(prevCategory => (prevCategory === category ? null : category));
    setActiveSortCategory(null); // Close any active sort dropdown
  };

  const toggleSortCategory = (category) => {
    setActiveSortCategory(prevCategory => (prevCategory === category ? null : category));
    setActiveFilterCategory(null); // Close any active filter dropdown
  };

  const toggleDisplay = () => {
    setActiveDisplay(prev => !prev);
    setActiveFilterCategory(null);
    setActiveSortCategory(null);
  };

  const handleFilterSelect = (category, value, toggle = false) => {
    const { multiple, operator } = filterCategories[category];
    const currentValues = filters[category] || (multiple ? [] : '');
    let newValue;
    let filter;

    if (multiple) {
      if (currentValues.includes(value)) {
        newValue = currentValues.filter(v => v !== value);
        if (toggle) {
          newValue.push(value.startsWith('NOT ') ? value.slice(4) : `NOT ${value}`);
        }
      } else {
        newValue = [...currentValues, value];
      }
    } else {
      // For single selection, toggle between value, NOT value, and empty
      if (currentValues === value) {
        newValue = [`NOT ${value}`];
      } else {
        newValue = [value];
      }
    }

    // If multiple values, combine them into a single clause.  Negative clauses are always
    // combined with AND.  Positive clauses are combined with the operator specified in the
    // filter definition.
    if (Array.isArray(newValue)) {
      const negativeClauses = newValue.filter(v => v.startsWith('NOT ')).join(' AND ');
      const positiveClauses = newValue.filter(v => !v.startsWith('NOT ')).join(` ${operator} `);

      filter = `
        ${negativeClauses ? `(${negativeClauses})` : ''}
        ${negativeClauses && positiveClauses ? ' AND ' : ''}
        ${positiveClauses ? `(${positiveClauses})` : ''}
      `.replace(/\n/g, ' ').replace(/\s+/g, ' ').trim();
    }

    // Add new code to handle automatic sort selection
    const selectedOption = filterCategories[category].options.find(opt => 
      resolve(opt.value) === (value.startsWith('NOT ') ? value.slice(4) : value)
    );
    
    if (selectedOption?.sort) {
      const [sortLabel, sortDirection] = selectedOption.sort.split(':');
      // Find the corresponding sort option by matching the label
      Object.entries(sortCategories).forEach(([sortCategory, definition]) => {
        const sortOption = definition.options.find(opt => opt.label === sortLabel);
        if (sortOption) {
          handleSortSelect(sortCategory, sortOption.value, false, sortDirection);
        }
      });
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      [category]: newValue
    }));

    onFilterChange(category, filter);
  };

  const removeFilter = (category, value) => {
    const { multiple, operator } = filterCategories[category];
    let newValue;

    if (multiple) {
      newValue = filters[category].filter(v => v !== value && v !== `NOT ${value}`);
    } else {
      newValue = [];
    }

    // Check if removed filter had an associated sort
    const removedOption = filterCategories[category].options.find(opt => 
      resolve(opt.value) === (value.startsWith('NOT ') ? value.slice(4) : value)
    );
    
    if (removedOption?.sort) {
      const [sortLabel, sortDirection] = removedOption.sort.split(':');
      // Find and remove the corresponding sort
      Object.entries(sortCategories).forEach(([sortCategory, definition]) => {
        const sortOption = definition.options.find(opt => opt.label === sortLabel);
        if (sortOption) {
          removeSort(sortCategory, sortOption.value, false);
        }
      });
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      [category]: newValue
    }));

    onFilterChange(category, newValue, operator);
    setActiveFilterCategory(null);
  };

  const handleSortSelect = (category, value, runQuery = true, sortDirection) => {
    let newSort = '';
    const sortDefinition = sortCategories[category].options.find(opt => opt.value === value);
    const existingSort = sorts.find(sort => sort.startsWith(value));
    const defaultDirection = sortDirection || sortDefinition?.direction || 'desc';

    if (existingSort) {
      // Toggle sort direction
      const [field, direction] = existingSort.split(':');
      const newDirection = direction === 'desc' ? 'asc' : 'desc';
      newSort = `${field}:${newDirection}`;
    } else {
      // Use specified direction or default to 'desc'
      newSort = `${value}:${defaultDirection}`;
    }

    // Add hidden filter if specified in the sort option
    // @TODO If we want to support multiple hidden filters, we'll need to change this.
    if (sortDefinition?.hiddenFilter) {
      setFilters(prevFilters => ({
        ...prevFilters,
        ['hidden']: sortDefinition.hiddenFilter
      }));
      onFilterChange('hidden', sortDefinition.hiddenFilter);
    }

    setSorts(newSort ? [newSort] : []);
    onSortChange(newSort ? [newSort] : [], runQuery);
    setActiveSortCategory(null);
  };

  const removeSort = (category, field, runQuery = true) => {
    const sortDefinition = sortCategories[category].options.find(opt => opt.value === field);
    const newSorts = sorts.filter(sort => !sort.startsWith(field));

    // Remove hidden filter if specified in the sort option
    if (sortDefinition?.hiddenFilter) {
      setFilters(prevFilters => ({
        ...prevFilters,
        ['hidden']: []
      }));
      onFilterChange('hidden', []);
    }

    setSorts(newSorts);
    onSortChange(newSorts.length ? newSorts : [defaultSort], runQuery);
  };

  const handleDisplaySelect = (value) => {
    setDisplayCount(value);
    onDisplayChange(value);
    setActiveDisplay(false);
  };

  const renderSelectedFilters = (category) => {
    const selected = filters[category]?.sort();
    if (!selected) return null;

    const values = Array.isArray(selected) ? selected : [selected];

    return values.map(value => {
      const isNegated = value.startsWith('NOT ');
      const baseValue = isNegated ? value.slice(4) : value;
      const label = filterCategories[category]
        .options
        .find(opt => resolve(opt.value) === baseValue)
        ?.label || baseValue;
      
      return (
        <Badge
          key={value}
          variant={isNegated ? 'danger' : 'primary'}
          className="mr-2 mb-1 clickable"
          onClick={(e) => {
            e.stopPropagation();
            handleFilterSelect(category, value, true);
          }}
        >
          <FaTimes
            className="mr-1"
            onClick={(e) => {
              e.stopPropagation();
              removeFilter(category, value);
            }}
          />
          <span className="pr-1">{label}</span>
        </Badge>
      );
    });
  };

  const renderSelectedSorts = (category) => {
    if (!sorts.length) return null;

    return sorts.map(sort => {
      const [field, direction] = sort.split(':');
      const label = sortCategories[category].options.find(opt => opt.value === field)?.label || field;
      return (
        <Badge
          key={sort}
          variant="primary" // Changed to primary
          className="mr-2 mb-1 clickable"
        >
          <FaTimes
            className="mr-1" 
            onClick={() => removeSort(category, field)}
          />
          <span onClick={(e) => {
            e.stopPropagation();
            handleSortSelect(category, field);
          }}>
            {label} {direction === 'asc' ? <FaSortUp /> : <FaSortDown />}
          </span>
        </Badge>
      );
    });
  };

  const renderFilterOptions = (category) => {
    const { options, multiple } = filterCategories[category];
    const selectedValues = filters[category] || (multiple ? [] : '');

    return (
      <div className="filter-options mt-2">
        {options.map(option => {

          const optionValue = resolve(option.value);

          // Check if the option is selected
          const isSelected = Array.isArray(selectedValues)
            ? selectedValues.includes(optionValue)
            : selectedValues === optionValue;

          // Check if the option is negated (NOT value)
          const isNegated = Array.isArray(selectedValues)
            ? selectedValues.includes(`NOT ${optionValue}`)
            : selectedValues === `NOT ${optionValue}`;

          // Get the selected value
          const selectedValue = isNegated ? `NOT ${optionValue}` : optionValue;

          // Set badge class based on selection state
          let badgeClass = 'badge-outline-secondary';
          if (isSelected || isNegated) badgeClass = 'badge-outline-primary';

          return (
            <span
              key={optionValue}
              className={`badge ${badgeClass} mr-2`}
              style={{ cursor: 'pointer' }}
              onClick={() => handleFilterSelect(category, selectedValue)}
            >
              {option.label}
            </span>
          );
        })}
      </div>
    );
  };

  const renderSortOptions = (category) => {
    const { options } = sortCategories[category];

    return (
      <div className="sort-options mt-2">
        {options.map(option => {
          const currentSort = sorts.find(sort => sort.startsWith(option.value));
          const direction = currentSort ? currentSort.split(':')[1] : 'desc';
          const isSelected = Boolean(currentSort);
          const Icon = direction === 'asc' ? FaSortUp : FaSortDown;

          return (
            <span
              key={option.value}
              className={`badge ${isSelected ? 'badge-primary' : 'badge-outline-primary'} mr-2`}
              style={{ cursor: 'pointer' }}
              onClick={() => handleSortSelect(category, option.value)}
            >
              {option.label} <Icon />
            </span>
          );
        })}
      </div>
    );
  };

  const renderSelectedDisplay = () => {
    return (
      <Badge variant="primary" className="mr-2 mb-1 clickable">
        <span onClick={(e) => {
          e.stopPropagation();
          toggleDisplay();
        }}>
          {displayCount} Rows
        </span>
      </Badge>
    );
  };

  const renderDisplayOptions = () => {
    const options = [10, 25, 50, 100];

    return (
      <div className="display-options mt-2">
        {options.map(option => (
          <span
            key={option}
            className={`badge ${option === displayCount ? 'badge-primary' : 'badge-outline-primary'} mr-2`}
            style={{ cursor: 'pointer' }}
            onClick={() => handleDisplaySelect(option)}
          >
            {option} Rows
          </span>
        ))}
      </div>
    );
  };

  return (
    <div className="mb-3">
      {/* Render filter category selectors */}
      {Object.entries(filterCategories).map(([category, { label }]) => (
        <span
          key={category}
          onClick={() => toggleFilterCategory(category)}
          className="small mr-4 text-muted clickable"
        >
          <FaFilter className="mr-1" /> {label}
          <span className="ml-2">{renderSelectedFilters(category)}</span>
        </span>
      ))}

      {/* Render sort category selectors */}
      {Object.entries(sortCategories).map(([category, { label }]) => (
        <span
          key={category}
          onClick={() => toggleSortCategory(category)}
          className="small mr-4 text-muted clickable"
        >
          <FaSortUp className="mr-1" /> {label}
          <span className="ml-2">{renderSelectedSorts(category)}</span>
        </span>
      ))}

      {/* Add display selector */}
      <span
        onClick={toggleDisplay}
        className="small mr-4 text-muted clickable"
      >
        <FaEye className="mr-1" /> Display
        <span className="ml-2">{renderSelectedDisplay()}</span>
      </span>

      {/* Render active filter options */}
      {activeFilterCategory && (
        <div className="mt-2">
          {renderFilterOptions(activeFilterCategory)}
        </div>
      )}

      {/* Render active sort options */}
      {activeSortCategory && (
        <div className="mt-2">
          {renderSortOptions(activeSortCategory)}
        </div>
      )}

      {/* Add display options */}
      {activeDisplay && (
        <div className="mt-2">
          {renderDisplayOptions()}
        </div>
      )}
    </div>
  );
};


/**
 * Returns the resolved value if the input is a function, otherwise returns the value itself.
 *
 * @param {any} value - The value to check or execute.
 * @param {...any} args - Arguments to pass if the value is a function.
 * @returns {any} - The resolved value.
 */
const resolve = (value, ...args) => {
  return typeof value === 'function' ? value(...args) : value;
};

export default ResultsFilter;
