import React, { useState, useEffect, useRef } from "react";
import { Form, Badge } from "react-bootstrap";
import { FaCircleCheck } from "react-icons/fa6";
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { Parser } from 'expr-eval';

/**
 * Checks if all conditions for a question are met based on current form data
 * @param {Object} question - The question object to check conditions for
 * @param {Object} formData - The current form data
 * @param {Array} allQuestions - All questions in the form config
 * @returns {boolean} - True if all conditions are met or no conditions exist
 */
export function conditionsMet(question, formData, allQuestions) {
  if (!question.conditions) return true;

  try {
    const parser = new Parser();
    const expr = parser.parse(question.conditions);
    
    // Create a context with all possible question IDs
    const context = {};
    allQuestions.forEach(q => context[q.id] = formData[q.id] === undefined ? null : formData[q.id]);

    return expr.evaluate(context) === true;
  } catch (error) {
    console.error('Failed to evaluate conditions:', error);
    return false;
  }
}

/**
 * A pure presentational component for rendering dynamic forms
 * @param {Object} props - Component props
 * @param {Object} props.formConfig - The form configuration object containing questions and structure
 * @param {Object} props.formData - The current form data
 * @param {Function} props.onInputChange - Callback when form inputs change
 * @param {string} props.defaultTab - Default active tab name
 * @returns {JSX.Element} Rendered form component
 */
const DynamicForm = ({ formConfig, formData, onInputChange, defaultTab}) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const firstInputRef = useRef(null);

  useEffect(() => {
    // Focus the first input element when component mounts
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  const checkConditions = (question) => {
    return conditionsMet(question, formData, formConfig.questions);
  };

  const renderQuestion = (question, isFirst = false) => {
    if (!checkConditions(question)) {
      return null;
    }

    switch (question.type) {
      case "checkbox":
        return (
          <div key={question.id} className="d-flex align-items-start mb-1">
            <span style={{ minWidth: '150px' }}>
              {question.level > 0 && (
                <MdSubdirectoryArrowRight className={`mr-2 ml-${question.level}`} />
              )}
              <Form.Label className="me-3 mb-0">{question.question}</Form.Label>
            </span>
            <div>
              {question.options.map((option, idx) => {
                let checked = false;

                if (question.options.length === 1) {
                  checked = formData[question.id] === option || formData[question.id] === "Yes";
                } else {
                  checked = (formData[question.id] || []).includes(option);
                }

                return (
                  <Form.Check
                    key={option}
                    ref={isFirst && idx === 0 ? firstInputRef : null}
                    type="checkbox"
                    label={question.options.length > 1 ? option : ''}
                    value={option}
                    checked={checked}
                    onChange={(e) => {
                      if (question.options.length === 1) {
                        onInputChange(question.id, e.target.checked ? option : null);
                      } else {
                        const selected = formData[question.id] || [];
                        const updated = e.target.checked
                          ? [...selected, option]
                          : selected.filter((o) => o !== option);
                        onInputChange(question.id, updated);
                      }
                    }}
                  />
                );
              })}
            </div>
          </div>
        );

      case "select":
        return (
          <div key={question.id} className="d-flex align-items-start mb-1">
            <span style={{ minWidth: '150px' }}>
              {question.level > 0 && (
                <MdSubdirectoryArrowRight className={`mr-2 ml-${question.level}`} />
              )}
              <Form.Label className="me-3 mb-0">{question.question}</Form.Label>
            </span>
            <Form.Select
              ref={isFirst ? firstInputRef : null}
              value={formData[question.id] || ""}
              onChange={(e) => onInputChange(question.id, e.target.value)}
            >
              <option value="">Select an option</option>
              {question.options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </div>
        );

      case "input_text":
        return (
          <div key={question.id} className="d-flex align-items-start mb-1">
            <span style={{ minWidth: '150px' }}>
              {question.level > 0 && (
                <MdSubdirectoryArrowRight className={`mr-2 ml-${question.level}`} />
              )}
              <Form.Label className="me-3 mb-0">{question.question}</Form.Label>
            </span>
            <Form.Control
              ref={isFirst ? firstInputRef : null}
              type="text"
              size="sm"
              placeholder={question.placeholder}
              value={formData[question.id] || ""}
              onChange={(e) => onInputChange(question.id, e.target.value)}
            />
          </div>
        );

      case "radio":
        return (
          <div key={question.id} className="d-flex align-items-start mb-1">
            <span style={{ minWidth: '150px' }}>
              {question.level > 0 && (
                <MdSubdirectoryArrowRight className={`mr-2 ml-${question.level}`} />
              )}
              <Form.Label className="me-3 mb-0">{question.question}</Form.Label>
            </span>
            <div className="d-flex gap-3">
              {question.options.map((option, idx) => (
                <Form.Check
                  key={option}
                  ref={isFirst && idx === 0 ? firstInputRef : null}
                  type="radio"
                  name={question.id}
                  label={option}
                  value={option}
                  checked={formData[question.id] === option}
                  onChange={(e) => onInputChange(question.id, e.target.value)}
                  inline
                />
              ))}
            </div>
          </div>
        );

      case "input_textarea":
        return (
          <div key={question.id} className="d-flex align-items-start mb-1">
            <span style={{ minWidth: '150px' }}>
              {question.level > 0 && (
                <MdSubdirectoryArrowRight className={`mr-2 ml-${question.level}`} />
              )}
              <Form.Label className="me-3 mb-0">{question.question}</Form.Label>
            </span>
            <Form.Control
              ref={isFirst ? firstInputRef : null}
              as="textarea"
              rows={question.rows || 3}
              placeholder={question.placeholder}
              value={formData[question.id] || ""}
              onChange={(e) => onInputChange(question.id, e.target.value)}
            />
          </div>
        );

      default:
        return null;
    }
  };

  const organizeQuestionsByTab = (questions) => {
    const tabs = {};
    let hasDefinedTabs = false;
    
    questions.forEach(question => {
      if (question.tabGroup) {
        hasDefinedTabs = true;
        if (!tabs[question.tabGroup]) {
          tabs[question.tabGroup] = [];
        }
        tabs[question.tabGroup].push(question);
      } else {
        if (!tabs['default']) {
          tabs['default'] = [];
        }
        tabs['default'].push(question);
      }
    });
    
    // If no tabs were defined, return null
    return hasDefinedTabs ? tabs : null;
  };

  const getUnansweredCount = (questions) => {
    return questions.filter(question => {
      if (!checkConditions(question)) {
        return false;
      }
      
      const value = formData[question.id];
      if (!value) return true;
      if (Array.isArray(value) && value.length === 0) return true;
      if (value === "") return true;
      return false;
    }).length;
  };

  const getAnsweredCount = (questions) => {
    return questions.filter(question => {
      if (!checkConditions(question)) {
        return false;
      }
      
      const value = formData[question.id];
      if (!value) return false;
      if (Array.isArray(value) && value.length === 0) return false;
      if (value === "") return false;
      return true;
    }).length;
  };

  if (!formConfig) {
    return <div>Loading form...</div>;
  }

  const questionsByTab = organizeQuestionsByTab(formConfig.questions);

  // If no tabs are defined, render questions directly
  if (!questionsByTab) {
    return (
      <div className="dynamic-form">
        {formConfig.questions.map((question, index) => renderQuestion(question, index === 0))}
      </div>
    );
  }

  return (
    <>
      <div className="d-flex gap-2 mb-2">
        {Object.entries(questionsByTab).map(([tabName, questions]) => {
          const answeredCount = getAnsweredCount(questions);
          const unansweredCount = getUnansweredCount(questions);

          return (
            <Badge
              key={tabName}
              variant={activeTab === tabName ? "primary" : "secondary"}
              className={`clickable px-2 py-1 mr-2 ${activeTab === tabName ? 'badge-outline-primary' : 'badge-outline-secondary'}`}
              onClick={() => setActiveTab(tabName)}
            >
              <span className="">{tabName}</span>
              {unansweredCount > 0 && (
                <Badge 
                  variant="warning" 
                  text="dark"
                  className="ml-1"
                >
                  {unansweredCount}
                </Badge>
              )}
              {unansweredCount === 0 && answeredCount > 0 && (
                <FaCircleCheck className="ml-1" />
              )}
            </Badge>
          );
        })}
      </div>
      {Object.entries(questionsByTab).map(([tabName, questions]) => (
        <div key={tabName} className={`dynamic-form ${activeTab === tabName ? "d-block" : "d-none"}`}>
          {questions.map((question, index) => renderQuestion(question, index === 0 && tabName === activeTab))}
        </div>
      ))}
    </>
  );
};

export default DynamicForm;
